import { _ } from '../utils.js'

export default (el) => {
    const target = _(el.dataset.target, 'element').node()
    const targetClass = target.dataset.class

    const toggleClassAndRequired = () => {
        const inputs = _('', 'required').nodesFrom(target)
        if (
            (el.type === 'select-one' &&
                (el.value.includes(el.dataset.match1) ||
                    el.value.includes(el.dataset.match2))) ||
            (el.type === 'radio' && el.checked === true)
        ) {
            target.classList.remove(targetClass)
            inputs.forEach((input) => {
                input.required = true
            })
        } else {
            target.classList.add(targetClass)
            inputs.forEach((input) => {
                input.required = false
                input.value = ''
            })
        }
    }

    el.addEventListener('change', toggleClassAndRequired)

    setTimeout(() => {
        toggleClassAndRequired()
    }, 500)
}
