/* eslint-disable no-unused-vars */
import $ from 'jquery'
// Sentry
import sentry from './modules/sentry.js'
sentry()

// Dependencies
import { _ } from './utils.js'
import 'sharetastic'
import 'pickadate/lib/picker.js'
import 'pickadate/lib/picker.date.js'

// Classes
import BasketContact from './modules/basket-contact.js'
import BasketCountData from './modules/basket-count-data.js'
import BasketSubscriptionPrice from './modules/basket-subscription-price.js'
import ContactForm from './modules/contact-form.js'
import DuplicateMarkup from './modules/duplicate-markup.js'
import DynamicBanner from './modules/dynamic-banner.js'
import FilterProductType from './modules/filter-product-type.js'
import FormReset from './modules/form-reset.js'
import HeaderScroll from './modules/headerScroll.js'
import InputToggle from './modules/input-toggle.js'
import Installation from './modules/installation.js'
import ListingForm from './modules/listing.js'
import Menu from './modules/menu.js'
import ModalForm from './modules/modal-form.js'
import MultistepForm from './modules/multistep-form.js'
import PcaLookup from './modules/pca-lookup.js'
import ProductSubscriptionPrice from './modules/product-subscription-price.js'
import ProductVehicleForm from './modules/product-vehicle-form.js'
import PromoCode from './modules/promo-code.js'
import ScrollCheck from './modules/scroll-check.js'
import Slider from './modules/slider.js'
import Social from './modules/social.js'
import Tabs from './modules/tabs.js'
import Tooltip from './modules/tooltip.js'
import basketRemoveProduct from './modules/basket-remove-product.js'
import basketValidation from './modules/basket-validation.js'
import equalHeight from './modules/equal-height.js'
import feefoDuplicateRemover from './modules/feefo-duplicates.js'
import googleViewedProducts from './modules/google-viewed-products.js'
import gtmTrack from './modules/gtm-track.js'
import radioToggle from './modules/radio-toggle.js'
import toggleClass from './modules/toggle-class.js'

feefoDuplicateRemover()

// prettier-ignore
{

    _('address-finder-main').el().map((i, el) => new PcaLookup(el))
    _('basket-contact').el().map((i, el) => new BasketContact(el))
    _('basket-count-data').el().map((i, el) => new BasketCountData(el))
    _('basket-subscription').el().map((i, el) => new BasketSubscriptionPrice(el, i))
    _('duplicate-markup').el().map((i, el) => new DuplicateMarkup(el))
    _('dynamic-banner').el().map((i, el) => new DynamicBanner(el))
    _('equal-height').el().map((i, el) => equalHeight(el))
    _('filter-product-types').el().map((i, el) => new FilterProductType(el))
    _('form-reset').el().map((i, el) => new FormReset(el))
    _('google-viewed-products').el().map((i, el) => googleViewedProducts(el))
    _('gtm-track').el().map((i, el) => gtmTrack(el))
    _('input-toggle').el().map((i, el) => new InputToggle(el))
    _('installation').el().map((i, el) => new Installation(el, i))
    _('listing').el().map((i, el) => new ListingForm(el))
    _('modal-form').el().map((i, el) => new ModalForm(el))
    _('multistep-form').el().map((i, el) => new MultistepForm(el))
    _('product-form').el().map((i, el) => new ProductVehicleForm(el))
    _('product-only-form').el().map((i, el) => new ProductSubscriptionPrice(el, i))
    _('promo-code').el().map((i, el) => new PromoCode(el, i))
    _('recaptcha-form').el().map((i, el) => new ContactForm(el))
    _('scroll-check').el().map((i, el) => new ScrollCheck(el))
    _('slider').el().map((i, el) => new Slider(el))
    _('tooltip').el().map((i, el) => new Tooltip(el))
}
// Touch Detection
$(document).on('touchstart', () => {
    $('html').removeClass('no-touch').addClass('touch')
})

_('generic-datepicker').el().pickadate({
    selectYears: 50,
    selectMonths: true,
    max: new Date(),
})
