import article from '../classes/news-article.js'
import caseStudy from '../classes/case-study.js'
import quote from '../classes/quote.js'
import standardQuote from '../classes/standard-quote.js'

export default {
    article,
    caseStudy,
    quote,
    standardQuote,
}
