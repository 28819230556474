import $ from 'jquery'
import { _ } from '../utils.js'
import PcaLookup from './pca-lookup.js'

export default class Installation {
    constructor(el) {
        this.element = $(el)
        this.postcodeFind = this.element.find(
            _('get-installation-details').el(),
        )
        this.dateInput = this.element.find(_('installation-datepicker').str())
        this.timeInput = this.element.find(_('installation-times').str())
        this.dateTimeSelect = this.element.find(
            _('date-time-select', 'element').str(),
        )
        this.postcodeError = this.element.find(
            _('postcode-error', 'element').str(),
        )
        this.installerID = this.element.find(_('installer-id').str())
        this.availableDates = []
        this.results = []
        this.addressInput = this.element.find(_('address-finder').str())
        this.installationBlocks = _(
            'installation-details-block',
            'element',
        ).el()

        this.postcodeFind.on('change', this.getAvailableDates)
        this.dateInput.on('change', this.checkTimes)
        this.hideShowInstallationDate()

        if (this.addressInput.length > 0) {
            this.lookup = new PcaLookup(this.addressInput[0])
            this.lookup.on('addressSelected', this.getAvailableDates)
        }
    }

    hideShowInstallationDate = () => {
        if (this.postcodeFind.val().length > 1) {
            this.dateTimeSelect.attr('disabled', false)
            this.timeInput.prop('disabled', false)
            this.installationBlocks.removeClass('hidden')
            this.getAvailableDates()
        }
    }

    getAvailableDates = () => {
        this.dateTimeSelect.attr('disabled', false)

        $.ajax({
            url:
                '/api/installation-slots?' +
                'postcode=' +
                this.postcodeFind.val(),
            method: 'get',
            success: (response) => {
                this.results = response
                this.availableDates = []
                this.availableDates.push(true)

                if (!response.errorMessage) {
                    response.map((item) => {
                        if (
                            item.isMorningSlotAvailable ||
                            item.isAfternoonSlotAvailable
                        ) {
                            const installationDate = item.installationDate
                                .split('-')
                                .map((number, index) => {
                                    if (index === 1) {
                                        return parseInt(number - 1, 10)
                                    } else {
                                        return parseInt(number, 10)
                                    }
                                })
                            this.availableDates.push(installationDate)
                        }
                    })
                }
                this.dateInput.prop('disabled', false)
                this.dateInput.pickadate({
                    disable: this.availableDates,
                })
                this.postcodeError.text('')
            },
            error: () => {
                this.postcodeError.text('Please put in a valid postcode')
                this.dateInput.prop('disabled', true)
                this.timeInput.prop('disabled', true)
                this.timeInput.html(
                    '<option value="">Choose</option><option value="AM">09:00 - 13:00</option><option value="PM">13:00 - 17:00</option>',
                )
            },
        })
    }

    checkTimes = (e) => {
        let dateValue = this.dateInput.pickadate('get', 'select')
        this.timeInput.html(
            '<option value="">Choose</option><option value="AM">09:00 - 13:00</option><option value="PM">13:00 - 17:00</option>',
        )
        const dateModalTriggerChoose = $(e.currentTarget)
            .closest(_('product-form').el())
            .find(_('date-time-modal-trigger-choose', 'element').str())
        const dateModalTriggerEdit = $(e.currentTarget)
            .closest(_('product-form').el())
            .find(_('date-time-modal-trigger-edit', 'element').str())

        if (dateModalTriggerChoose.length > 0) {
            dateModalTriggerChoose.addClass('hidden')
            dateModalTriggerEdit.removeClass('hidden').attr('disabled', false)
        }

        if (dateValue) {
            this.timeInput.prop('disabled', false)
            dateValue.month = dateValue.month + 1
            dateValue.date = ('0' + dateValue.date).slice(-2)
            dateValue.month = ('0' + dateValue.month).slice(-2)
            dateValue =
                dateValue.year + '-' + dateValue.month + '-' + dateValue.date
            this.results
                .filter((item) => {
                    return item.installationDate === dateValue
                })
                .map((item) => {
                    this.installerID.val(item.installerId)
                    this.timeInput.html('<option value="">Choose</option>')
                    if (item.isMorningSlotAvailable) {
                        this.timeInput.append(
                            '<option value="AM">09:00 - 13:00</option>',
                        )
                    }
                    if (item.isAfternoonSlotAvailable) {
                        this.timeInput.append(
                            '<option value="PM">13:00 - 17:00</option>',
                        )
                    }
                })
        }
    }
}
