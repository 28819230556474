// ---------------------------------------------------------------------------//
//
// Toggle Class
//
// ----------------------------------------------------------------------------//

// ----------------------------------------------------------------------------//
// Attributes on trigger element:
// ----------------------------------------------------------------------------//
//
// data-behaviour="toggle-class"    - For setting up the click event
// data-target="element"            - For targeting another element
// data-class="trigger-class"       - Class to be toggled (optional)
// data-toggle-text="New Text"      - Alternate text state (optional)
// data-kill-overflow               - Stops the page scroll when active.
//                                    Pass an optional class name to toggle
//                                    on the html element (optional)
//
// ----------------------------------------------------------------------------//

// ----------------------------------------------------------------------------//
// Attributes on target element:
// ----------------------------------------------------------------------------//
//
// data-element="element"           - To match the triggers data-target
// data-class="target-class"        - Class to be toggled
//
// ----------------------------------------------------------------------------//

// ----------------------------------------------------------------------------//
// Usage:
// ----------------------------------------------------------------------------//
//
// import './modules/toggle-class'
//
// ----------------------------------------------------------------------------//
import $ from 'jquery'
import { _ } from '../utils.js'
import ScrollCheck from './scroll-check.js'

class Toggle {
    constructor() {
        const toggle = _('toggle-class').str()
        $(document).on('click', toggle, this.toggleClass)
        this.win = $(window)
        this.html = $('html')
        this.body = $('body')
        this.scrollPos = 0
        this.scroll = true
        this.toggleOnLoad = _('load-toggle').el()
        this.toggleOnLoadTarget = _(
            this.toggleOnLoad.data('target'),
            'element',
        ).el()

        if (this.toggleOnLoad.length > 0) {
            this.toggleClass('', this.toggleOnLoadTarget)
        }
    }

    toggleClass = (e, trigger = false) => {
        if (!trigger) {
            e.preventDefault()
            trigger = $(e.currentTarget)
        }

        const target = _(trigger.data('target'), 'element').el()
        const triggerClass = trigger.data('class')
        const targetClass = target.data('class')
        const killOverflow = trigger.attr('data-kill-overflow')
        const toggleTable = trigger.attr('data-toggle-table')
        const toggleText = trigger.data('toggle-text')

        _('toggle-class')
            .el()
            .filter('[data-type="has-content"]')
            .map((index, item) => {
                $(item).removeClass(triggerClass)
            })

        if (triggerClass) {
            trigger.toggleClass(triggerClass)
        }

        if (toggleText) {
            trigger.data('toggle-text', trigger.text())
            trigger.text(toggleText)
        }

        if (typeof killOverflow === 'string') {
            this.toggleScroll(killOverflow || 'kill-overflow')
        }

        if (typeof toggleTable === 'string') {
            _('scroll-check')
                .el()
                .map((i, el) => new ScrollCheck(el))
        }

        target.toggleClass(targetClass)
    }

    stopScroll = (className) => {
        this.scroll = false
        this.scrollPos = this.win.scrollTop()
        this.html.addClass(className)
        this.body.css({ height: this.win.height(), overflow: 'hidden' })
        this.body.children().first().css({ marginTop: -this.scrollPos })
        this.win.scrollTop(0)
    }

    startScroll = (className) => {
        this.scroll = true
        this.html.removeClass(className)
        this.body.css({ height: 'auto', overflow: 'auto' })
        this.body.children().first().css({ marginTop: 0 })
        this.win.scrollTop(this.scrollPos)
    }

    toggleScroll = (className) => {
        if (this.scroll) {
            this.stopScroll(className)
        } else {
            this.startScroll(className)
        }
    }
}

export default new Toggle()
