import $ from 'jquery'
import { _ } from '../utils.js'
import productComparisonMatrix from '../classes/product-comparison-matrix.js'
import productComparisonMatrixHeader from '../classes/product-comparison-matrix-header.js'
import productComparisonMatrixPrice from '../classes/product-comparison-matrix-price.js'
import productComparisonMatrixURL from '../classes/product-comparison-matrix-links.js'

export default class ProductComparison {
    constructor(el) {
        this.element = $(el)
        this.productCheckbox = this.element.find(_('compare-product').el())
        this.comparisonCTA = this.element.find(
            _('product-comparison-cta', 'element').str(),
        )
        this.comparisonProductName = this.element.find(
            _('comparison-product-name', 'element').str(),
        )
        this.comparisonProductPrice = this.element.find(
            _('comparison-product-price', 'element').str(),
        )
        this.comparisonProductLinks = this.element.find(
            _('comparison-product-links', 'element').str(),
        )
        this.comparisonProductsFeatures = this.element.find(
            _('comparison-product-features', 'element').str(),
        )
        this.unhighlightButton = this.element.find(_('unhighlight').el())

        this.productCheckbox.on('change', this.productSelect)
        this.unhighlightButton.on('click', this.unhighlightDifferenceRows)
    }

    productSelect = () => {
        const maximumReached =
            this.productCheckbox.filter(':checked').length >= 2
        const uncheckedProducts = this.productCheckbox.filter(':not(:checked)')
        let compareURL = '/api/products/compare?'

        uncheckedProducts
            .parent()
            .toggleClass('checkbox--disabled', maximumReached)
        this.comparisonCTA.toggleClass('banner--active', maximumReached)

        this.productCheckbox.filter(':checked').map((index, item) => {
            compareURL += `productIds[]=${item.id}&`
        })

        if (maximumReached) {
            $.ajax({
                url: compareURL,
                type: 'GET',
                success: (data) => {
                    this.comparisonProductItems = this.element.find(
                        _('comparison-product-items', 'element').str(),
                    )
                    this.comparisonProductItems.remove()
                    this.checkDifferenceHighlight = this.element.find(
                        _('check-difference').el(),
                    )

                    const productPrices = []

                    data.products.map((index) => {
                        productPrices.push(index.price)
                        const priceMatch = productPrices[0] === productPrices[1]
                        const formattedPrice = new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                        }).format(index.price)

                        this.comparisonProductName.append(
                            productComparisonMatrixHeader({
                                productName: index.heading,
                                thatchamLevel: index.thatchamLevel.label,
                            }),
                        )

                        if (productPrices[0] !== productPrices[1]) {
                            this.checkDifferenceHighlight.addClass(
                                'table-comparison__row--alt-bg',
                            )
                        }

                        this.comparisonProductPrice.append(
                            productComparisonMatrixPrice({
                                price: formattedPrice,
                            }),
                        )

                        if (priceMatch === false) {
                            this.checkDifferenceHighlight.addClass(
                                'table-comparison__row--alt-bg',
                            )
                        } else {
                            this.checkDifferenceHighlight.removeClass(
                                'table-comparison__row--alt-bg',
                            )
                        }

                        this.comparisonProductLinks.append(
                            productComparisonMatrixURL({
                                url: index.url,
                            }),
                        )
                    })
                    this.comparisonProductsFeatures.empty()

                    for (const property in data.comparisons) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                data.comparisons,
                                property,
                            )
                        ) {
                            const property1 = data.comparisons[property][1]
                            const property2 = data.comparisons[property][2]
                            // prettier-ignore
                            const formattedProductA = typeof property1 === 'string' ? property1.toUpperCase() : property1
                            // prettier-ignore
                            const formattedProductB = typeof property2 === 'string' ? property2.toUpperCase() : property2
                            this.comparisonProductsFeatures.append(
                                productComparisonMatrix({
                                    description: data.comparisons[property][0],
                                    productA: formattedProductA,
                                    productB: formattedProductB,
                                    differenceCheck:
                                        formattedProductA === formattedProductB,
                                }),
                            )
                        }
                    }
                },
                error: function (err) {
                    console.error(err)
                },
            })
        }
    }

    unhighlightDifferenceRows = () => {
        this.element
            .find(_('check-difference').el())
            .toggleClass('table-comparison__row--alt-bg')
    }
}
