function feefoBadge(data) {
    if (data.feefo) {
        let markup = ''
        markup += `
        <a href="https://www.feefo.com/en-GB/reviews/tracker-network-uk-ltd/products/${data.hypenatedName}?parentSku=${data.feefo}&displayFeedbackType=PRODUCT&timeFrame=YEAR"
            target="_blank">
            <img alt="Feefo logo" border="0"
                src="https://api.feefo.com/api/logo?merchantidentifier=tracker-network-uk-ltd&template=Product-Stars-White-175x44.png&parentvendorref=${data.feefo}"
                title="See what our customers say about this product"/></a>`

        return markup
    } else {
        return ''
    }
}

const vehicleAddedBasketCount = (data) =>
    `<div class="flex flex--space-between flex--align-center">
        <div>
            <p class="copy copy--small copy--mid-opacity">${
                data.basketCount
            } item${data.basketCount > 1 ? 's' : ''}</p>
            <h1 class="heading--1 u-mb12">${data.productName}</h1>
            <span class="tag u-mb28">Thatcham ${data.thatchamCat}</span>
        </div>
        ${feefoBadge(data)}
    </div>`

export default vehicleAddedBasketCount
