import $ from 'jquery'
import { _ } from '../utils.js'

export default class RemoveProduct {
    constructor() {
        this.removeTrigger = _('remove-product').str()
        $(document).on('click', this.removeTrigger, this.removeAndUpdatePrices)

        this.events = {
            removeProduct: () => {},
        }
    }

    removeAndUpdatePrices = (e) => {
        e.preventDefault()

        const target = $(e.currentTarget).closest(
            _('target-product-field', 'element').str(),
        )
        target.remove()
        this.events.removeProduct.call()
    }

    on(event, callback) {
        this.events[event] = callback
    }
}
