import { _ } from '../utils.js'

export default class DynamicBanner {
    constructor(element) {
        this.instance = element
        this.triggers = _('banner-trigger').nodes()
        this.banners = _('banner-image', 'element').nodesFrom(this.instance)
        this.baseBanner = this.banners[0]
        this.bannerActiveClass = 'hero__image--active'

        document.addEventListener('DOMContentLoaded', () => {
            const initialBanner = document.querySelector(
                '[data-initial-banner]',
            )

            if (initialBanner) {
                this.swapActiveBanner(
                    initialBanner.getAttribute('data-initial-banner'),
                )
            }
        })

        document.addEventListener('click', (event) => {
            this.triggers = _('banner-trigger').nodes()

            if (this.triggers.includes(event.target)) {
                const targetBannerId =
                    event.target.getAttribute('data-target-banner')
                this.swapActiveBanner(targetBannerId)
            }
        })
    }

    swapActiveBanner = (targetBannerId) => {
        const targetBanner = this.banners.find(
            (banner) => banner.getAttribute('data-banner') === targetBannerId,
        )

        if (targetBanner) {
            this.hideActiveBanners()
            this.showTargetBanner(targetBanner)
        } else {
            this.hideActiveBanners()
            this.showTargetBanner(this.baseBanner)
        }
    }

    showTargetBanner = (targetBanner) => {
        targetBanner.classList.add(this.bannerActiveClass)
    }

    hideActiveBanners = () => {
        this.banners.forEach((banner) => {
            if (banner.classList.contains(this.bannerActiveClass)) {
                banner.classList.remove(this.bannerActiveClass)
            }
        })
    }
}
