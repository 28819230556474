/* global dataLayer */
import $ from 'jquery'
import { _ } from '../utils.js'

export default class ModalForm {
    constructor(el) {
        this.form = $(el)
        this.formElements = this.form.find(_('form-elements', 'element').str())
        this.endpoint = this.form.attr('action')
        this.method = this.form.attr('method')
        this.submit = this.form.find(_('submit', 'element').str())
        this.errorMessage = this.form.find(
            _('form-error-message', 'element').str(),
        )
        this.successMessage = this.form.find(
            _('form-success-message', 'element').str(),
        )
        this.formModalButton = this.form.find(_('toggle-class').el())

        const self = this
        this.form.parsley().on('form:submit', function () {
            self.formSubmit()
            return false
        })
    }

    formSubmit = () => {
        this.submit.prop('disabled', true)

        $.ajax({
            url: this.endpoint,
            type: this.method,
            data: this.form.serialize(),
            success: (response) => {
                if (response.success) {
                    if (this.form.find('[name="contact-business-type"]')) {
                        dataLayer.push({
                            event: 'ctaForm',
                            serviceType: this.form.find(
                                '[name="contact-business-type"]',
                            )[0].selectedOptions[0].value,
                        })
                    }
                    this.successMessage.removeClass('hidden')
                    this.submit.prop('disabled', false)
                    this.form[0].reset()

                    setTimeout(() => {
                        this.successMessage.addClass('hidden')
                        this.formModalButton.trigger('click')
                    }, 3000)
                }
            },
            error: (response) => {
                if (response.error) {
                    this.submit.prop('disabled', false)
                    this.errorMessage.removeClass('hidden')

                    setTimeout(() => {
                        this.errorMessage.addClass('hidden')
                    }, 3000)
                }
            },
        })
    }
}
