import $ from 'jquery'
import { _ } from '../utils.js'

class Tabs {
    constructor() {
        _('toggle-tab').el().on('click', this.toggleTab)
        _('slide-tab').el().on('click', this.toggleSlide)
    }

    toggleTab = (e) => {
        const tab = $(e.currentTarget)
        const tabActiveClass = tab.data('class')
        const target = tab.data('target')
        const content = _(target, 'element').el()
        const contentActiveClass = content.data('class')
        const container = tab.closest(_('tab-container', 'element').str())

        container
            .find(_('toggle-tab').str())
            .not(tab)
            .map((index, item) => {
                $(item).removeClass(tabActiveClass)
            })

        tab.toggleClass(tabActiveClass)

        container
            .find(_('tab-content').str())
            .not(content)
            .map((index, item) => {
                $(item).removeClass(contentActiveClass)
            })

        content.toggleClass(contentActiveClass)

        return false
    }

    toggleSlide = (e) => {
        const tab = $(e.currentTarget)
        const tabActiveClass = tab.data('class')
        const tabParent = tab.parent()
        const index = tabParent.index()

        _('slide-tab').el().removeClass(tabActiveClass)
        tab.addClass(tabActiveClass)
        _('tab-slider', 'element').el().slick('slickGoTo', index)

        return false
    }
}

export default new Tabs()
