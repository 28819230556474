import { _ } from '../utils.js'
import * as Sentry from '@sentry/browser'
import { isBrowserSupported } from '../utils/browser-support.js'

export default () => {
    const env = _('site-info', 'element').node()
    if (!env) {
        return false
    }
    const environment = env.dataset.environment || process.env.NODE_ENV
    const isLocal = environment === 'development' || environment === 'local'
    const loggedIntoCMS = env.dataset.c5 === 'true'
    if (isLocal || !isBrowserSupported() || loggedIntoCMS) {
        return false
    }

    Sentry.init({
        dsn: 'https://4f8bd94edb7e3440c3f34a9e7a48a91a@o4507050262921216.ingest.us.sentry.io/4507339233099776',
        integrations: [],
        environment: environment,
    })
}
