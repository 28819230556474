export default {
    key: window.POSTCODE_ANYWHERE_KEY,
    search: { countries: 'GBR' },
    fields: [
        {
            name: 'address_postCode',
            field: 'PostalCode',
            mode: (pca) => pca.fieldMode.SEARCH,
        },
        {
            name: 'address1',
            field: 'Line1',
            mode: (pca) => pca.fieldMode.POPULATE,
        },
        {
            name: 'address2',
            field: 'Line2',
            mode: (pca) => pca.fieldMode.POPULATE,
        },
        {
            name: 'townCity',
            field: 'City',
            mode: (pca) => pca.fieldMode.POPULATE,
        },
        {
            name: 'county',
            field: 'Province',
            mode: (pca) => pca.fieldMode.POPULATE,
        },
        {
            name: 'postcode',
            field: 'PostalCode',
            mode: (pca) => pca.fieldMode.POPULATE,
        },
        {
            name: 'country',
            field: 'CountryName',
            mode: (pca) => pca.fieldMode.COUNTRY,
        },
    ],
}
