import $ from 'jquery'
import { _ } from '../utils.js'
import 'slick-carousel'

export default class Slick {
    constructor(el, items = false) {
        const options = $(el).data()
        this.mobile = window.matchMedia('(max-width: 567px)')
        this.tablet = window.matchMedia('(max-width: 769px)')
        this.el = $(el)

        this.sliderTabContainer = _('slide-tabs').el()
        this.sliderTabs = this.sliderTabContainer.find(_('slide-tab').str())
        this.activeTabClass = 'tabs__link--active'
        this.slicked = false

        this.sliderWithNav = _('slider-with-nav', 'element').str()

        if (options.nav) {
            this.defaultOptions = {
                arrows: false,
                asNavFor: '.slider--with-nav',
                focusOnSelect: true,
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        } else if (options.withNav) {
            this.defaultOptions = {
                arrows: false,
                asNavFor: '.slider--nav',
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        } else {
            this.defaultOptions = {
                centerMode: options.center ? options.center : false,
                arrows: true,
                dots: false,
                autoplay: options.autoplay ? options.autoplay : false,
                centerPadding: 0,
                slidesToShow: 1,
            }
        }

        if (items) {
            $.extend(this.defaultOptions, {
                items: items,
            })
        }

        if (options.mobile) {
            this.checkResize()
            $(window).on('resize', this.checkResize)
            $.extend(this.defaultOptions, {
                arrows: false,
            })
        } else if (options.tablet) {
            this.checkResize()
            $(window).on('resize', this.checkResize)
            $.extend(this.defaultOptions, {
                slidesToShow: 1,
            })
        } else {
            this.slickIt()
        }
    }

    slickIt = () => {
        this.slicked = true
        this.el.slick(this.defaultOptions)

        if (this.sliderTabContainer.length > 0) {
            this.el.on('afterChange', (event, slick, currentSlide) => {
                const tab = this.sliderTabs.eq(currentSlide)

                this.sliderTabs.removeClass(this.activeTabClass)
                tab.toggleClass(this.activeTabClass)
            })
        }
    }

    checkResize = () => {
        if (this.mobile.matches || this.tablet.matches) {
            if (!this.slicked) {
                this.slickIt()
            }
        } else {
            if (this.slicked) {
                this.slicked = false
                this.el.slick('unslick')
            }
        }
    }
}
