import $ from 'jquery'
import { _ } from '../utils.js'
import {
    Session,
    Listing,
    Paginator,
    LoadMore,
} from './../components/api-toolkit.js'
import templates from '../components/templates.js'
import category from '../classes/news-category.js'
import Slider from './slider.js'
import 'slick-carousel'

export default class ListingForm {
    constructor(el) {
        this.listingForm = $(el)
        this.listingContainer = this.listingForm.find(
            _('results', 'element').str(),
        )
        this.loadMore = this.listingContainer.data('append')
        this.loadMoreButton = this.listingForm.find(_('load-more').el())
        this.pagination = this.listingForm.find(
            _('pagination', 'element').str(),
        )
        this.paginationInput = this.listingForm.find(
            _('pagination-input', 'element').str(),
        )
        this.categoryContainer = this.listingForm.find(
            _('category-select', 'element').str(),
        )
        this.listingType = this.listingForm.data('type')
        this.search = this.listingForm.find(_('search-news').el())
        this.resized = false

        this.session = new Session({
            form: this.listingForm,
        })

        this.listing = new Listing({
            element: this.listingContainer,
            template: templates[this.listingType],
            appendItems: this.loadMore ? this.loadMore : false,
        })

        $(window).on('resize', () => {
            this.resized = true
            if (
                this.listingContainer.hasClass('slick-initialized') &&
                this.resized
            ) {
                this.resized = false
                this.session.connect()
            }
        })

        this.listing.on('before', () => {
            if (this.listingContainer.hasClass('slick-initialized')) {
                this.listingContainer.slick('unslick')
            }
        })

        this.listing.on('after', () => {
            if (this.listingContainer.data('slider')) {
                this.listingSlider = new Slider(
                    this.listingContainer[0],
                    this.listingContainer.children(),
                )
            }
        })

        this.paginator = new Paginator({
            element: this.pagination,
            input: this.paginationInput,
            session: this.session,
            limit: 6,
            classes: 'paginator__button',
            ends: true,
        })

        this.loadMore = new LoadMore({
            element: this.loadMoreButton,
            input: this.paginationInput,
            session: this.session,
        })

        const self = this
        this.session.on('success', function () {
            self.handleResponse(this.response)
        })

        this.search.on('keyup', function () {
            clearTimeout(self.timer)
            self.timer = setTimeout(function () {
                self.handleForm()
            }, 300)
        })

        this.categoryContainer.on('change', function () {
            self.paginationInput.val('1')
            window.history.replaceState(
                {},
                'Category',
                `${self.categoryContainer.find('input:checked').val()}`,
            )
            self.handleForm()
        })

        this.listingForm.on('submit', function (e) {
            e.preventDefault()
        })

        this.handleForm()
    }

    handleResponse = (response) => {
        if (this.categoryContainer.length > 0) {
            this.handleCategory(response.categories)
        }

        if (window.location.search) {
            const categoryParam = window.location.search.split('=')[1]

            if (
                !document
                    .querySelector('html')
                    .classList.contains('ccm-edit-mode')
            ) {
                $(`#category-${categoryParam}`).trigger('click')
            }
        }

        if (response.results && response.results.length > 0) {
            response.results
                .filter((item) => item.type)
                .map((item) => {
                    item.template = templates[item.type]
                })
            this.listing.render(response.results)
            if (this.pagination.length > 0) {
                this.paginator.render(
                    response.pagination.page,
                    response.pagination.totalPages,
                )
            }
            if (this.loadMoreButton.length > 0) {
                this.loadMore.render(
                    response.pagination.page,
                    response.pagination.totalPages,
                )
            }
        } else {
            this.listing.render(
                '<div class="grid__item grid__item--grow"><div class="content-section content-section--small"><p class="heading--3">Sorry, no results match the criteria.</p></div></div>',
                true,
            )
            if (this.pagination.length > 0) {
                this.paginator.render(
                    response.pagination.page,
                    response.pagination.totalPages,
                )
            }
        }

        $('[data-element="banner-heading"]').text(response?.page_content?.title)
        $('[data-element="banner-subheading"]').text(
            response?.page_content?.content,
        )
    }

    handleForm = () => {
        this.session.connect()
    }

    handleCategory = (response) => {
        this.categoryContainer.empty()
        for (let i = 0; i < response.length; i++) {
            const item = category(response[i])
            this.categoryContainer.append(item)
        }
    }
}
