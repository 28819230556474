import $ from 'jquery'
import { _ } from '../utils.js'

export default class scrollCheck {
    constructor(el) {
        this.element = $(el)

        this.scrollFixedHeight = this.element.find(
            _('scroll-fixed', 'element').str(),
        )
        this.header = this.element.find(_('scroll-header', 'element').str())
        this.inner = this.element.find(_('scroll-inner', 'element').str())

        this.normalWidth = 0
        this.scrollWidth = 0

        if (
            this.scrollFixedHeight[0].scrollHeight >
            this.scrollFixedHeight.height()
        ) {
            this.normalWidth = this.scrollFixedHeight[0].offsetWidth
            this.scrollWidth = this.normalWidth - this.inner.innerWidth()
            this.scrollFixedHeight.css({
                marginRight: +this.scrollWidth + 'px',
            })
            this.header.css({ paddingRight: +this.scrollWidth + 'px' })
        }
    }
}
