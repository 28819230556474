import $ from 'jquery'
import { _ } from '../utils.js'

export default class BasketSubscriptionPrice {
    constructor(el) {
        this.element = $(el)
        this.priceRow = _('target-price-row', 'element').str()
        this.subscriptionMonths = _(
            'target-subscription-months',
            'element',
        ).str()
        this.priceSubTotal = _('price-sub-total', 'element').el()
        this.priceDiscount = _('price-discount', 'element').el()
        this.priceTotal = _('price-total', 'element').el()
        this.subscriptionPriceTarget = _(
            'subscription-price-target',
            'element',
        ).str()
        this.element.on(
            'change',
            _('subscription-price-toggle', 'element').str(),
            this.toggleSubscriptionPrice,
        )
        this.errorMessage = this.element.find(
            _('subscription-error', 'element').el(),
        )
    }

    toggleSubscriptionPrice = (e) => {
        const currentSelect = $(e.target)
        const orderId = currentSelect.data('order-id')
        const subscriptionId = currentSelect.val()
        const subscriptionMonthsValue = $(
            'option:selected',
            currentSelect,
        ).attr('data-subscription-months')
        const currentSelectPrice = $('option:selected', currentSelect).attr(
            'data-subscription-price',
        )

        $.ajax({
            url:
                '/api/basket/change-subscription/' +
                orderId +
                '/' +
                subscriptionId,
            method: 'get',
            success: (response) => {
                if (response.success === true) {
                    const currentRow = $(
                        this.priceRow + '[data-order-id="' + orderId + '"]',
                    )

                    currentRow
                        .find(this.subscriptionPriceTarget)
                        .text('+ £' + currentSelectPrice)
                    currentRow
                        .find(this.subscriptionMonths)
                        .text(subscriptionMonthsValue)
                    this.priceSubTotal.text(
                        '£' +
                            response.basket.subTotal.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )
                    this.priceDiscount.text(
                        '-£' +
                            response.basket.discount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )
                    this.priceTotal.text(
                        '£' +
                            response.basket.total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )
                } else {
                    this.errorMessage.html(response.responseJSON.message)
                }
            },
            error: (response) => {
                this.errorMessage.html(response.responseJSON.message)
            },
        })
    }
}
