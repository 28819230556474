import $ from 'jquery'
import { _ } from '../utils.js'

const radioToggle = _('radio-toggle').el()
const toggleSections = _('toggle-section', 'element').str()
const toggleSectionsEl = _('toggle-section', 'element').el()
const requiredTarget = _('required', 'element').el()
const toggleSectionInput = _('toggle-section-input').el()
const hiddenClass = 'hidden'

function checkRequired() {
    toggleSectionsEl.each(function () {
        const thisSection = $(this)
        if (thisSection.hasClass(hiddenClass)) {
            requiredTarget.attr('required', false)
        } else {
            requiredTarget.attr('required', true)
        }
    })
}

function toggleSection(e) {
    const target = $('#' + $(e.currentTarget).data('target'))
    target.siblings(toggleSections).addClass(hiddenClass)
    target.removeClass(hiddenClass)
    toggleSectionsEl.find(toggleSectionInput).val('')
    checkRequired()
}

function checkToggles() {
    radioToggle.each(function () {
        if ($(this).is(':checked')) {
            const target = $('#' + $(this).data('target'))
            target.siblings(toggleSections).addClass(hiddenClass)
            target.removeClass(hiddenClass)
        }
    })
}

function init() {
    checkToggles()
    checkRequired()
    radioToggle.on('change', toggleSection)
}

export default init()
