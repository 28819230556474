import $ from 'jquery'
import { _ } from '../utils.js'

class HeaderScroll {
    constructor() {
        const header = $(_('menu-background', 'element').str())
        const headerBgClass = 'header__wrapper--with-background'
        let state = false
        window.addEventListener(
            'scroll',
            function () {
                if (state !== window.pageYOffset > 0) {
                    header.toggleClass(headerBgClass, window.pageYOffset > 0)
                    state = window.pageYOffset > 0
                }
            },
            { passive: true },
        )
    }
}

export default new HeaderScroll()
