function featuredVehicles(data) {
    if (data) {
        let markup = ''
        for (let i = 0; i < data.length; i++) {
            markup += `
            <svg role="img" class="product-card__compatibility-icon u-mr-5" width="45" height="25">
                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#${data[i].svgId}"></use>
            </svg>
            <div class="sr-only">${data[i].name}</div>`
        }
        return markup
    } else {
        return ''
    }
}

function feefoBadge(data) {
    if (data.feefoSku) {
        let markup = ''
        markup += `
        <a href="${data.feefoURL}"
            target="_blank" class="u-mt u-size-0">
            <img alt="Feefo logo" border="0"
                src="https://api.feefo.com/api/logo?merchantidentifier=tracker-network-uk-ltd&template=Imported-Reviews-200x15.png&parentvendorref=${data.feefoSku}"
                title="See what our customers say about this product"/></a>`

        return markup
    } else {
        return ''
    }
}

// prettier-ignore
const trackerToolResult = (data) => (
    `<div class="grid__item">
        <article class="product-card">
            <div class="product-card__body">
                <div class="flex flex--space-between flex--keep-row@small u-mb-half">
                    <span class="tag tag--text u-mb@small">In stock</span>
                    <div class="checkbox checkbox--flipped product-card__checkbox">
                        <div class="checkbox__container">
                            <input class="checkbox__input" type="checkbox" id="${data.id}" data-behaviour="compare-product"/>
                            <label class="checkbox__label" for="${data.id}">Compare</label>
                        </div>
                    </div>
                </div>
                <h1 class="heading--1">${data.heading}</h1>
                <div class="flex flex--align-center flex--wrap flex--keep-row@small u-mar-bot-15">
                    <span class="tag tag--narrow u-mt u-mr">Thatcham ${data.thatchamLevel.label}</span>
                    ${feefoBadge(data)}
                </div>
                <div class="u-mar-bot-15">
                    <p class="copy copy--small copy--mid-opacity u-mar-bot-5">Compatibility:</p>
                    ${featuredVehicles(data.vehicleTypes)}
                </div>
                <div class="wysiwyg wysiwyg--tick-list u-mb22">
                    <p>${data.featuresListSummary}</p>
                </div>
            </div>
            <div class="product-card__footer">
                <p class="copy copy--small copy--mid-opacity u-mar-bot-5">From</p>
                <div class="flex flex--align-end u-mb">
                    <h2 class="heading--2 u-mr-5">£${data.price}</h2>
                </div>
                <a
                    class="button button--full"
                    href="${data.url}"
                    data-element="select-item"
                    data-gtm-item-list-name="Related products"
                    data-gtm-item-name="${data.heading}"
                    data-gtm-item-price="${data.price}"
                    data-gtm-item-id="${data.id}"
                    data-gtm-item-category="${data.productCategory}"
                >View product</a>

            </div>
        </article>
    </div>`)

export default trackerToolResult
