/* global dataLayer */
import $ from 'jquery'
import { _ } from '../utils.js'

export default class ContactForm {
    constructor(el) {
        this.element = $(el)
        this.enquiryValue = _('contact-enquiry', 'element').el()
        this.typeValue = _('contact-type', 'element').el()
        this.element.parsley().on('form:success', this.formSubmit)
    }

    formSubmit = () => {
        dataLayer.push({
            event:
                this.enquiryValue.val() === 'technical-support'
                    ? 'help_form'
                    : 'contact_submit',
            customerType: this.typeValue.filter(':checked').val(),
            enquiryType: this.enquiryValue.val(),
        })
    }
}
