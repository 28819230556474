/* eslint-disable camelcase */
/* global dataLayer */
import $ from 'jquery'
import { _ } from '../utils.js'
import productAddedToBasket from '../classes/vehicle-added-to-basket.js'
import vehicleAddedBasketTotal from '../classes/vehicle-added-basket-total.js'
import vehicleAddedBasketCount from '../classes/vehicle-added-basket-count.js'

export default class ProductVehicleForm {
    constructor(el, firstLoad = false) {
        this.element = $(el)
        this.data = {}
        this.editdetailsButton = this.element.find(
            _('edit-vehicle-details-save').el(),
        )

        this.saveDetails = this.element.find(_('modal-submit', 'element').el())
        this.validateEitherError = this.element.find(
            _('validate-either-error-message', 'element').str(),
        )

        this.endpoints = {
            class: '/api/vehicles/makes',
            make: '/api/vehicles/ranges',
            range: '/api/vehicles/models',
            model: '/api/vehicles/colours',
            colour: '',
        }
        this.priceRow = _('target-price-row', 'element').str()
        this.populateTypeInput = this.element.find(
            _('populate-type', 'element').str(),
        )

        this.element.on('click', _('find-reg').str(), this.populateVehicleInfo)
        this.element.on(
            'click',
            _('switch-manual-input').str(),
            this.switchToManualInput,
        )
        this.element.on(
            'change',
            _('manual-input', 'element').str(),
            this.findVehicleInfo,
        )

        if (this.element.data('basket') === true) {
            const self = this
            this.element.parsley().on('form:validate', function (formInstance) {
                const ok =
                    formInstance.isValid({ group: 'input1', force: true }) ||
                    formInstance.isValid({ group: 'input2', force: true })
                self.validateEitherError.toggleClass('hidden', ok)
                if (!ok) {
                    formInstance.validationResult = false
                }
            })
        }

        if (!firstLoad) {
            if (this.element.data('basket') !== true) {
                this.element
                    .parsley()
                    .on('form:validate', function (formInstance) {
                        $('[data-element="and-or-group"]').map((i, e) => {
                            const individualValidateEitherError = $(e).find(
                                _(
                                    'validate-either-error-message',
                                    'element',
                                ).el(),
                            )
                            const parsleyTarget = $(e).data('id')
                            const ok =
                                formInstance.isValid({
                                    group: parsleyTarget + '-input1',
                                    force: true,
                                }) ||
                                formInstance.isValid({
                                    group: parsleyTarget + '-input2',
                                    force: true,
                                })
                            individualValidateEitherError.toggleClass(
                                'hidden',
                                ok,
                            )
                            if (!ok) {
                                formInstance.validationResult = false
                            }
                        })
                    })
            }

            const self = this
            this.element.parsley().on('form:submit', function () {
                self.formSubmit()

                return false
            })
        }

        this.saveDetails.on('click', this.updateVehicleDetails)
    }

    formSubmit = () => {
        const productId = this.element.data('product-id')
        const addId = this.element
            .find(_('id-input', 'element').str())
            .data('add-id')
        const productName = this.element.data('product-name')
        const hyphenatedProductName = this.element.data('hyphenated-name')
        const feefoSku = this.element.data('feefo-sku')
        const productPrice = this.element.data('product-price')
        const productCategory = this.element.data('product-category')
        const products = this.element.find(
            _('target-product-field', 'element').str(),
        )

        products.map((index, item) => {
            const subscriptionPrice = $(item)
                .find(
                    '[data-element="subscription-price-toggle"]:checked + label span',
                )
                .text()
                .replace('+', '')
                .replace('£', '')

            dataLayer.push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'GDP',
                    add: {
                        products: [
                            {
                                name: productName,
                                id: productId,
                                price:
                                    parseFloat(productPrice) +
                                    parseFloat(subscriptionPrice), // installed price + subscription price
                                brand: 'Tracker',
                                category: productCategory,
                                quantity: 1,
                            },
                        ],
                    },
                },
            })
        })

        this.productRow = this.element.find(
            _('target-product-field', 'element').str(),
        )
        const self = this
        const productData = {
            details: [],
            product: addId,
        }

        this.productRow.each(function () {
            const thisRow = $(this)
            const isLookup =
                thisRow.find(_('manual-input', 'element').str()).val() === ''

            let range = ''
            let make = ''
            let model = ''
            if (isLookup) {
                range = thisRow
                    .find(_('vehicle-populate').el())
                    .filter('[data-type="range"]')
                    .val()
                make = thisRow
                    .find(_('vehicle-populate').el())
                    .filter('[data-type="make"]')
                    .val()
                model = thisRow
                    .find(_('vehicle-populate').el())
                    .filter('[data-type="model"]')
                    .val()
            } else {
                range = thisRow
                    .find(_('manual-input', 'element').el())
                    .filter('[data-type="range"]')
                    .val()
                make = thisRow
                    .find(_('manual-input', 'element').el())
                    .filter('[data-type="make"]')
                    .val()
                model = thisRow
                    .find(_('manual-input', 'element').el())
                    .filter('[data-type="model"]')
                    .val()

                if (make === 'other') {
                    make = thisRow
                        .find(_('vehicle-populate').el())
                        .filter('[data-type="make"]')
                        .val()
                    range = thisRow
                        .find(_('vehicle-populate').el())
                        .filter('[data-type="range"]')
                        .val()
                    model = thisRow
                        .find(_('vehicle-populate').el())
                        .filter('[data-type="model"]')
                        .val()
                }

                if (range === 'other') {
                    range = thisRow
                        .find(_('vehicle-populate').el())
                        .filter('[data-type="range"]')
                        .val()
                    model = thisRow
                        .find(_('vehicle-populate').el())
                        .filter('[data-type="model"]')
                        .val()
                }
            }
            const vehiclePopulate = thisRow.find(_('vehicle-populate').el())
            const manualInput = thisRow.find(_('manual-input', 'element').el())
            productData.details.push({
                type: isLookup
                    ? vehiclePopulate.filter('[data-type="vehicleClass"]').val()
                    : manualInput.filter('[data-type="class"]').val(),
                make: make,
                range: range,
                model: model,
                colour: isLookup
                    ? vehiclePopulate.filter('[data-type="colour"]').val()
                    : manualInput.filter('[data-type="colour"]').val(),
                derivative: thisRow
                    .find(_('populate-input', 'element').el())
                    .filter('[data-type="derivative"]')
                    .val(),
                populateType: self.populateTypeInput.val(),
                subscription: thisRow
                    .find(_('subscription-price-toggle', 'element').str())
                    .filter(':checked')
                    .val(),
                subscriptionPrice: thisRow
                    .find(_('subscription-price-toggle', 'element').str())
                    .filter(':checked')
                    .data('subscription-price'),
                subscriptionLength: thisRow
                    .find(_('subscription-price-toggle', 'element').str())
                    .filter(':checked')
                    .data('subscription-length'),
                registrationNumber: thisRow
                    .find(_('reg-input', 'element').el())
                    .val(),
                vin: thisRow
                    .find(_('populate-input', 'element').el())
                    .filter('[data-type="vin"]')
                    .val(),
            })
        })

        _('basket-count-modal', 'element').el().empty()
        _('product-added-display', 'element').el().empty()
        _('basket-total-display', 'element').el().empty()
        _('chosen-subscription-length', 'element').el().val('')
        _('chosen-subscription-price', 'element').el().val('')

        $.ajax({
            url: '/api/basket/add',
            type: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(productData),
            success: (response) => {
                _('basket-count-modal', 'element')
                    .el()
                    .append(
                        vehicleAddedBasketCount({
                            basketCount: productData.details.length,
                            productName: productName,
                            thatchamCat: productCategory,
                            hypenatedName: hyphenatedProductName,
                            feefo: feefoSku,
                        }),
                    )

                for (let i = 0; i < productData.details.length; i++) {
                    this.color = productData.details[i].colour
                    this.registrationNumber =
                        productData.details[i].registrationNumber
                    this.make = productData.details[i].make
                    this.range = productData.details[i].range
                    this.model = productData.details[i].model
                    this.derivative = productData.details[i].derivative
                    this.subscriptionPrice =
                        productData.details[i].subscriptionPrice
                    this.subscriptionLength =
                        productData.details[i].subscriptionLength

                    _('product-added-display', 'element')
                        .el()
                        .append(
                            productAddedToBasket({
                                // UI TODO: Update these when available in the data from BE, then update basket-added-notification.js
                                reg: this.registrationNumber,
                                color: this.color,
                                make: this.make,
                                range: this.range,
                                model: this.model,
                                derivative: this.derivative,
                                subscriptionPrice: this.subscriptionPrice,
                                subscriptionLength: this.subscriptionLength,
                            }),
                        )
                }

                _('basket-total-display', 'element')
                    .el()
                    .append(
                        vehicleAddedBasketTotal({
                            basketSubtotal: _('product-price-total', 'element')
                                .el()
                                .text(),
                        }),
                    )

                _('trigger-add-modal', 'element').el().trigger('click')

                _('basket-items')
                    .el()
                    .text(response.count)
                    .removeClass('hidden')

                this.element
                    .find(_('product-price-total', 'element').el())
                    .text('£0.00')
                this.element
                    .find(_('manual-input', 'element').el())
                    .prop('selectedIndex', 0)
                this.element
                    .find(_('manual-input', 'element').el())
                    .slice(1, -1)
                    .attr('disabled', true)
                this.element
                    .find(_('remove-product').el().slice(1))
                    .trigger('click')
                this.element[0].reset()
                this.element
                    .find(_('basket-error', 'element').el())
                    .text('')
                    .addClass('hidden')

                document.cookie = 'basketItem=true;path=/'

                // Just get the form from the page simply
                const productForm = document.getElementById('product-form')

                // calculate the total price by mapping over the product data and adding the subscription price to the product price
                const actualTotalPrice = () => {
                    let total = 0
                    productData.details.map((item) => {
                        total +=
                            Number(productForm.dataset.productPrice) +
                            Number(item.subscriptionPrice)
                    })
                    return total
                }

                window.dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'GBP',
                        value: actualTotalPrice(),
                        items: productData.details.map((item, index) => {
                            return {
                                item_id: productForm.dataset.gtmProductId,
                                item_name: productForm.dataset.productName,
                                affiliation: 'tracker',
                                coupon: null,
                                discount: null,
                                index,
                                item_brand: 'tracker',
                                item_category:
                                    productForm.dataset.gtmProductCategory,
                                item_list_id: 'related_products',
                                item_list_name:
                                    productForm.dataset.gtmItemListName,
                                location_id: null,
                                price: actualTotalPrice(),
                                quantity: 1,
                            }
                        }),
                    },
                })
            },
            error: (response) => {
                this.element
                    .find(_('basket-error', 'element').el())
                    .html(response.responseJSON.message)
                    .removeClass('hidden')
            },
        })
    }

    populateVehicleInfo = (e) => {
        e.preventDefault()
        const self = this
        self.currentTarget = $(e.currentTarget)
        self.product = self.currentTarget.closest(_('product', 'element').str())
        self.productId = self.product.data('id')
        self.thisProduct = self.currentTarget.closest(
            _('target-product-field', 'element').str(),
        )
        self.derivativeLabel = self.thisProduct.find(
            _('label-derivative', 'element').str(),
        )
        self.vinLabel = self.thisProduct.find(_('label-vin', 'element').str())
        self.vehicleOptions = self.thisProduct.find(_('vehicle-options').str())
        self.vehiclePopulate = self.thisProduct.find(
            _('vehicle-populate').str(),
        )
        self.populateInput = self.thisProduct.find(
            _('populate-input', 'element').str(),
        )
        self.regError = self.thisProduct.find(_('reg-error').str())
        self.regInput = self.thisProduct.find(_('reg-input', 'element').str())
        self.vinInput = self.populateInput.filter('[data-type="vin"]')
        self.manualInput = self.vehicleOptions.find(
            _('manual-input', 'element').str(),
        )

        self.populateTypeInput.val('lookup')
        self.populateTypeInput.val()

        if (self.regInput.val() !== '') {
            $.ajax({
                url: '/api/vehicles/vrm-lookup',
                data: {
                    reg: self.regInput.val(),
                    product: self.productId,
                    availableSubscriptions: true,
                },
                type: 'GET',
                success: function (data) {
                    self.derivativeLabel.removeClass('hidden')
                    self.regError.addClass('hidden')
                    self.vehicleOptions
                        .addClass('hidden')
                        .find('select')
                        .prop('required', false)
                        .prop('disabled', true)
                    self.vehiclePopulate
                        .prop('required', true)
                        .prop('disabled', false)
                    self.vehiclePopulate
                        .not('[data-type="vin"]')
                        .removeClass('hidden')

                    self.populateInput
                        .filter('[data-type="derivative"]')
                        .removeClass('hidden')
                    self.populateInput.map((i, item) => {
                        const value = data[$(item).data('type')]
                        $(item).val(value)
                    })
                    self.manualInput.map((i, item) => {
                        $(item).val('')
                    })
                    self.populateInput
                        .filter('[data-type="vin"]')
                        .val(data.vin)
                        .addClass('hidden')

                    self.vinLabel.addClass('hidden')
                    self.populateInput
                        .filter('[data-type="firstRegistrationDate"]')
                        .val(data.firstRegistrationDate)

                    self.element.parsley().validate()
                },
                error: function () {
                    self.thisProductDetails.removeClass('hidden')
                    self.derivativeLabel.addClass('hidden')
                    self.populateInput
                        .filter('[data-type="derivative"]')
                        .val('')
                        .addClass('hidden')
                    self.regError.removeClass('hidden')
                    self.vehicleOptions
                        .removeClass('hidden')
                        .find('select')
                        .prop('required', true)
                        .prop('disabled', false)
                    self.vehiclePopulate
                        .addClass('hidden')
                        .prop('required', false)
                        .prop('disabled', true)
                        .val('')
                    self.getColourOptions(self.thisProduct)
                },
            })
        }
    }

    switchToManualInput = (e) => {
        e.preventDefault()
        const self = this
        self.currentTarget = $(e.currentTarget)
        self.thisProduct = self.currentTarget.closest(
            _('target-product-field', 'element').str(),
        )
        self.derivativeLabel = self.thisProduct.find(
            _('label-derivative', 'element').str(),
        )
        self.vehicleOptions = self.thisProduct.find(_('vehicle-options').str())
        self.regError = self.thisProduct.find(_('reg-error').str())
        self.vehiclePopulate = self.thisProduct.find(
            _('vehicle-populate').str(),
        )
        self.populateInput = self.thisProduct.find(
            _('populate-input', 'element').str(),
        )
        self.manualInput = self.vehicleOptions.find(
            _('manual-input', 'element').str(),
        )
        self.vinLabel = self.thisProduct.find(_('label-vin', 'element').str())

        // On basket if user switches to manual, trigger change in first select otherwise user will change input again
        if (self.currentTarget.data('type') === 'basket') {
            self.manualInput.filter('[data-type="class"]').trigger('change')
        }
        // On basket if user switches to manual, empty values incase user selects 'Other' and sees old values form populated inputs
        self.populateInput.map((i, item) => {
            $(item).val('')
        })

        self.derivativeLabel.addClass('hidden')
        self.vinLabel.removeClass('hidden')
        self.populateInput
            .filter('[data-type="derivative"]')
            .val('')
            .addClass('hidden')
        self.vehicleOptions
            .removeClass('hidden ')
            .find('select')
            .prop('required', true)
        self.vehicleOptions.find('[data-type="class"]').prop('disabled', false)
        self.vehiclePopulate
            .addClass('hidden')
            .prop('required', false)
            .prop('disabled', true)
        self.populateInput
            .filter('[data-type="vin"]')
            .removeClass('hidden input-field--disabled-style')
            .prop('disabled', false)
            .val('')
        self.getColourOptions(self.thisProduct)
        self.populateInput.filter('[data-type="firstRegistrationDate"]').val('')

        self.populateTypeInput.val('manual')
        self.populateTypeInput.val()
    }

    findVehicleInfo = (e) => {
        const input = $(e.currentTarget)
        const type = input.data('type')
        const inputMakeRangeModel = input.not('[data-type="class"]')

        this.data[type] = input.val()

        if (this.endpoints[type] !== '' && this.data[type] !== '') {
            $.ajax({
                url: this.endpoints[type],
                type: 'GET',
                data: this.data,
                success: (data) => {
                    let options = ''
                    const currentIndex = Object.keys(this.endpoints).indexOf(
                        type,
                    )
                    const nextInput = this.manualInput.filter((i, item) => {
                        return (
                            $(item).data('type') ===
                            Object.keys(this.endpoints)[currentIndex + 1]
                        )
                    })
                    nextInput
                        .prop('disabled', false)
                        .empty()
                        .html('<option value="">Please Select</option>')

                    data.map((i) => {
                        options =
                            options +
                            '<option value="' +
                            i.value +
                            '">' +
                            i.name +
                            '</option>'
                    })
                    options = options + '<option value="other">Other</option>'
                    nextInput.append(options)
                },
                error: function (error) {
                    console.warn(error)
                },
            })
        }
        if (
            inputMakeRangeModel.val() === 'other' ||
            inputMakeRangeModel.val() === 'Other'
        ) {
            input.attr('name', '')
            input
                .parent()
                .next(_('vehicle-options').str())
                .find('select')
                .not('[data-type="colour"]')
                .not('[data-type="range"]')
                .prop('required', false)
                .prop('disabled', true)
                .parent()
                .addClass('hidden')
            input
                .parent()
                .next(_('populate-input', 'element').str())
                .not('[data-type="derivative"]')
                .not('[data-type="colour"]')
                .removeClass('hidden input-field--disabled-style')
                .prop('required', true)
                .prop('disabled', false)
        } else {
            const inputName = input.data('name')
            input.attr('name', inputName)
            input
                .parent()
                .next(_('vehicle-options').str())
                .find('select')
                .not('[data-type="colour"]')
                .prop('required', true)
                .parent()
                .removeClass('hidden')
            input
                .parent()
                .next(_('populate-input', 'element').str())
                .not('[data-type="derivative"]')
                .not('[data-type="colour"]')
                .addClass('hidden input-field--disabled-style')
                .prop('required', false)
                .prop('disabled', true)
        }
    }

    getColourOptions = (thisParent) => {
        const colorSelect = thisParent
            .find(_('manual-input', 'element').str())
            .filter('[data-type="colour"]')
            .prop('disabled', false)
        $.ajax({
            url: '/api/vehicles/colours',
            type: 'GET',
            data: this.data,
            success: (data) => {
                let options = ''
                colorSelect.empty()
                options += '<option value="">Please Select</option>'
                data.map((i) => {
                    options =
                        options +
                        '<option value="' +
                        i.value +
                        '">' +
                        i.name +
                        '</option>'
                })
                colorSelect.append(options)
            },
            error: function (error) {
                console.warn(error)
            },
        })
    }

    updateVehicleDetails = (e) => {
        this.updateDetailsContainer = $(e.currentTarget).closest(
            _('update-vehicle-details', 'element').str(),
        )

        this.newVehicleReg = this.updateDetailsContainer
            .find(_('reg-input', 'element').el())
            .val()

        this.newVehicleVin = this.updateDetailsContainer
            .find(_('populate-input', 'element').el())
            .filter('[data-type="vin"]')
            .val()

        // Get values in Edit vehicle details form
        this.newVehicleType = this.updateDetailsContainer
            .find(_('vehicle-populate').el())
            .filter('[data-type="vehicleClass"]')
            .val()
        this.newVehicleMake = this.updateDetailsContainer
            .find(_('vehicle-populate').el())
            .filter('[data-type="make"]')
            .val()
        this.newVehicleRange = this.updateDetailsContainer
            .find(_('vehicle-populate').el())
            .filter('[data-type="range"]')
            .val()
        this.newVehicleModel = this.updateDetailsContainer
            .find(_('vehicle-populate').el())
            .filter('[data-type="model"]')
            .val()
        this.newVehicleColour = this.updateDetailsContainer
            .find(_('vehicle-populate').el())
            .filter('[data-type="colour"]')
            .val()
        this.newVehicleDerivative = this.updateDetailsContainer
            .find(_('populate-input', 'element').el())
            .filter('[data-type="derivative"]')
            .val()

        // Get values in Edit vehicle details Manual form
        this.newVehicleTypeManual = this.updateDetailsContainer
            .find(_('manual-input', 'element').str())
            .filter('[data-type="class"]')
            .val()
        this.newVehicleMakeManual = this.updateDetailsContainer
            .find(_('manual-input', 'element').str())
            .filter('[data-type="make"]')
            .val()
        this.newVehicleRangeManual = this.updateDetailsContainer
            .find(_('manual-input', 'element').str())
            .filter('[data-type="range"]')
            .val()
        this.newVehicleModelManual = this.updateDetailsContainer
            .find(_('manual-input', 'element').str())
            .filter('[data-type="model"]')
            .val()
        this.newVehicleColourManual = this.updateDetailsContainer
            .find(_('manual-input', 'element').str())
            .filter('[data-type="colour"]')
            .val()

        this.basketVehicleDetails = $(e.currentTarget)
            .closest(_('target-product-field', 'element').str())
            .find(_('basket-vehicle-details', 'element').str())

        // Put new values into product page from look up
        this.basketVehicleDetails
            .find(_('basket-vehicle-type', 'element').str())
            .text(this.newVehicleType)
        this.basketVehicleDetails
            .find(_('basket-vehicle-make', 'element').str())
            .text(this.newVehicleMake)
        this.basketVehicleDetails
            .find(_('basket-vehicle-range', 'element').str())
            .text(this.newVehicleRange)
        this.basketVehicleDetails
            .find(_('basket-vehicle-model', 'element').str())
            .text(this.newVehicleModel)
        this.basketVehicleDetails
            .find(_('basket-vehicle-colour', 'element').str())
            .text(this.newVehicleColour)

        if (this.newVehicleReg.length > 0) {
            this.basketVehicleDetails
                .find(_('basket-vehicle-reg-container', 'element').str())
                .removeClass('hidden')
            this.basketVehicleDetails
                .find(_('basket-vehicle-registration', 'element').str())
                .text(this.newVehicleReg)
        } else {
            this.basketVehicleDetails
                .find(_('basket-vehicle-reg-container', 'element').str())
                .addClass('hidden')
            this.basketVehicleDetails
                .find(_('basket-vehicle-registration', 'element').str())
                .text('')
        }

        if (this.newVehicleDerivative.length > 0) {
            this.basketVehicleDetails
                .find(_('basket-vehicle-derivative-container', 'element').str())
                .removeClass('hidden')
            this.basketVehicleDetails
                .find(_('basket-vehicle-derivative', 'element').str())
                .text(this.newVehicleDerivative)
        } else {
            this.basketVehicleDetails
                .find(_('basket-vehicle-derivative-container', 'element').str())
                .addClass('hidden')
            this.basketVehicleDetails
                .find(_('basket-vehicle-derivative', 'element').str())
                .text('')
        }

        // Hide vehicle VIN for auto lookup
        this.basketVehicleDetails
            .find(_('basket-vehicle-vin-container', 'element').str())
            .addClass('hidden')

        // Put new values into product page from manual
        if (this.newVehicleMakeManual && this.newVehicleMakeManual.length > 0) {
            this.basketVehicleDetails
                .find(_('basket-vehicle-type', 'element').str())
                .text(this.newVehicleTypeManual)
            this.basketVehicleDetails
                .find(_('basket-vehicle-make', 'element').str())
                .text(this.newVehicleMakeManual)
            this.basketVehicleDetails
                .find(_('basket-vehicle-range', 'element').str())
                .text(this.newVehicleRangeManual)
            this.basketVehicleDetails
                .find(_('basket-vehicle-model', 'element').str())
                .text(this.newVehicleModelManual)
            this.basketVehicleDetails
                .find(_('basket-vehicle-colour', 'element').str())
                .text(this.newVehicleColourManual)

            if (this.newVehicleVin.length > 0) {
                this.basketVehicleDetails
                    .find(_('basket-vehicle-vin-container', 'element').str())
                    .removeClass('hidden')
                this.basketVehicleDetails
                    .find(_('basket-vehicle-vin', 'element').str())
                    .text(this.newVehicleVin)
            } else {
                this.basketVehicleDetails
                    .find(_('basket-vehicle-vin-container', 'element').str())
                    .addClass('hidden')
                this.basketVehicleDetails
                    .find(_('basket-vehicle-vin', 'element').str())
                    .text('')
            }
        }

        this.updateDetailsContainer
            .closest(_('modal-basket').el())
            .removeClass('modal--open')
        $('html, body').css({ height: 'auto', overflow: 'auto' })
    }
}
