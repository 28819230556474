import $ from 'jquery'
import { _ } from '../utils.js'

export default class PromoCode {
    constructor(el) {
        this.element = $(el)
        this.promoCodeInput = this.element.find(
            _('promo-code-input', 'element').str(),
        )
        this.promoCodeApply = this.element.find(
            _('apply-promo-code', 'element').str(),
        )
        this.priceSubTotal = this.element.find(
            _('price-sub-total', 'element').str(),
        )
        this.priceDiscount = this.element.find(
            _('price-discount', 'element').str(),
        )
        this.priceTotal = this.element.find(_('price-total', 'element').str())
        this.promoCodeError = this.element.find(
            _('promo-code-error', 'element').str(),
        )
        this.displayDiscount = this.element.find(
            _('display-discount', 'element').str(),
        )

        this.promoCodeApply.on('click', this.checkPromoCode)
    }

    checkPromoCode = () => {
        $.ajax({
            url: '/api/basket/discount/' + this.promoCodeInput.val(),
            method: 'get',
            success: (response) => {
                if (response.success === true) {
                    this.promoCodeError.text('')
                    this.priceSubTotal.text(
                        '£' +
                            response.basket.subTotal.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )
                    this.priceDiscount.text(
                        '-£' +
                            response.basket.discount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )
                    this.priceTotal.text(
                        '£' +
                            response.basket.total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                    )

                    this.displayDiscount.removeClass('hidden')
                } else {
                    this.promoCodeError.text('Invalid Promotional Code')
                }
            },
            error: (error) => {
                this.promoCodeError.text('Invalid Promotional Code')
                console.warn(error)
            },
        })
    }
}
