import { _ } from '../utils.js'

export default class Tooltip {
    constructor(el) {
        this.el = el
        this.allTooltips = _('tooltip').nodes()
        this.allTooltipsButThisOne = this.allTooltips.filter((tooltip) => {
            return tooltip !== this.el
        })
        this.el.addEventListener('click', this.toggleClass)
    }

    toggleClass = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const trigger = e.currentTarget
        const target = _(trigger.dataset.target, 'element').node()
        const triggerClass = trigger.dataset.class
        const targetClass = target.dataset.class
        trigger.dataset.active = !(trigger.dataset.active === 'false')

        // document click to close anything open
        if (trigger.dataset.active) {
            document.addEventListener('click', this.closeAllTooltips)
        } else {
            document.removeEventListener('click', this.closeAllTooltips)
        }

        if (triggerClass) {
            // remove all other classes
            this.allTooltipsButThisOne.classList.remove(triggerClass)
            trigger.classList.toggle(triggerClass)
        }

        // remove all other classes
        this.allTooltipsButThisOne.map((el) => {
            _(el.dataset.target, 'element').node().classList.remove(targetClass)
        })

        target.classList.toggle(targetClass)
    }

    closeAllTooltips = () => {
        this.allTooltips.map((el) => {
            const trigger = el
            const target = _(trigger.dataset.target, 'element').node()
            const triggerClass = trigger.dataset.class
            const targetClass = target.dataset.class
            if (triggerClass) {
                el.classList.remove(triggerClass)
            }
            _(el.dataset.target, 'element').node().classList.remove(targetClass)
        })
        document.removeEventListener('click', this.closeAllTooltips)
    }
}
