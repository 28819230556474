/* global pca */
import $ from 'jquery'
import pcaConfig from '../config/pca-lookup.js'

export default class PcaLookup {
    constructor(el) {
        this.addressFinder = $(el)
        this.identifier = this.addressFinder.data('identifier')
        const self = this
        this.options = {
            key: pcaConfig.key,
            search: pcaConfig.search,
        }
        this.control = ''
        if (typeof pca !== 'undefined') {
            this.fields = pcaConfig.fields.reduce((fields, field) => {
                fields.push({
                    element: `${this.identifier}[${field.name}]`,
                    mode: field.mode(pca),
                    ...field,
                })
                return fields
            }, [])
            this.control = new pca.Address(this.fields, this.options)
            this.control.listen('populate', function () {
                self.events.addressSelected.call()
            })
        } else {
            console.warn('pca undefined')
        }

        this.events = {
            addressSelected: () => {},
        }
    }

    on(event, callback) {
        this.events[event] = callback
    }
}
