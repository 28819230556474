import $ from 'jquery'
import { _ } from '../utils.js'

class Menu {
    constructor() {
        this.element = _('menu').el()
        this.state = false

        this.toggle = this.element.find(_('toggle-menu').el())
        this.display = this.element.find(_('menu-display', 'element').str())
        this.children = this.display.find(_('header-children', 'element').str())
        this.menuBackground = $(_('menu-background', 'element').str())
        this.win = $(window)
        this.html = $('html')
        this.body = $('body')
        this.scrollPos = 0
        this.scroll = true

        this.closedHeightAndPadding = 70

        this.height = {
            false: this.closedHeightAndPadding,
            true: this.children.outerHeight() + this.closedHeightAndPadding,
        }

        $(window).on('resize', () => {
            this.resized = true
            setTimeout(() => {
                this.setHeight()
            }, 500)
        })

        this.toggle.on('click', this.toggleMenu)

        if (this.element.length <= 0) {
            return false
        }
    }

    setHeight = () => {
        this.height.true =
            this.children.outerHeight() + this.closedHeightAndPadding
        if (window.matchMedia('(min-width: 1024px)').matches) {
            this.display.removeAttr('style')
        } else {
            this.display.css('height', this.height[this.state])
        }
    }

    toggleMenu = (e) => {
        this.state = !this.state
        const killOverflow = $(e).attr('data-kill-overflow')
        this.toggleScroll(killOverflow || 'kill-overflow')
        this.menuBackground.toggleClass('header__wrapper--active')

        $(e.currentTarget).toggleClass('hamburger--active', [this.state])

        this.display.animate({
            height: this.height[this.state],
        })
    }

    stopScroll = (className) => {
        this.scroll = false
        this.scrollPos = this.win.scrollTop()
        this.html.addClass(className)
        this.body.css({ height: this.win.height(), overflow: 'hidden' })
        this.body.children().first().css({ marginTop: -this.scrollPos })
        this.win.scrollTop(0)
    }

    startScroll = (className) => {
        this.scroll = true
        this.html.removeClass(className)
        this.body.css({ height: 'auto', overflow: 'auto' })
        this.body.children().first().css({ marginTop: 0 })
        this.win.scrollTop(this.scrollPos)
    }

    toggleScroll = (className) => {
        if (this.scroll) {
            this.stopScroll(className)
        } else {
            this.startScroll(className)
        }
    }
}

export default new Menu()
