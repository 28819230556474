import { _ } from '../utils.js'
import 'sharetastic'
class Social {
    constructor() {
        const siteSocial = _('site-social', 'element').el()
        const shareSocial = _('share-social', 'element').el()

        if (siteSocial.length > 0) {
            siteSocial.sharetastic({
                popup: false,
                services: {
                    pinterest: false,
                    tumblr: false,
                    print: false,
                    facebook: {
                        href: 'https://www.facebook.com/trackeruk',
                    },
                    googleplus: false,
                    linkedin: {
                        href: 'http://www.linkedin.com/company/556210',
                    },
                    whatsapp: false,
                    email: false,
                    twitter: {
                        href: 'https://twitter.com/Tracker_UK',
                    },
                },
            })
        }

        if (shareSocial.length > 0) {
            shareSocial.sharetastic({
                popup: false,
                services: {
                    pinterest: false,
                    tumblr: false,
                    print: false,
                    whatsapp: false,
                    email: false,
                    googleplus: false,
                },
            })
        }
    }
}

export default new Social()
