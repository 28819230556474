import { _ } from '../utils.js'

export default (el) => {
    const triggers = _('trigger', 'element').nodesFrom(el)

    const makeGtmCall = (object) => {
        window.dataLayer.push(object)
    }

    const getGtmObject = (element) => {
        const { gtmDataLayer, gtmEventName } = element.dataset
        const object = {
            event: gtmEventName || '',
            ...JSON.parse(gtmDataLayer),
        }
        makeGtmCall(object)
    }

    triggers.forEach((element) => {
        if (element.dataset.trigger === 'page-load') {
            const { gtmCurrentPage } = element.dataset
            const excludedPages = ['contact', 'installation']

            if (!excludedPages.includes(gtmCurrentPage)) {
                getGtmObject(element)
            }
        }

        if (element.dataset.trigger === 'submit') {
            element.addEventListener('submit', (innerElement) => {
                getGtmObject(innerElement.target)
            })
        }
    })
}
