const caseStudyTemplate = (data) =>
    `<div class="grid__item">
		<article class="cta cta--square cta--no-max">
	        <img class="cta__image" alt="" src="${data.imageUrl}" data-behaviour="object-fit"/>
	        <div class="cta__content">
	            <h1 class="heading--2 space-bottom">${data.name}</h1>
	            <a href="${data.url}" class="button">Read More</a>
	        </div>
	    </article>
	</div>`
export default caseStudyTemplate
