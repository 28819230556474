function isFeaturedA(data) {
    if (!data) {
        return ''
    } else if (typeof data === 'string') {
        return `
            <span>${data}</span>`
    }
    return `
        <span class="sr-only">yes</span>
        <div class="table-comparison__circle"></div>`
}

function isFeaturedB(data) {
    if (!data) {
        return ''
    } else if (typeof data === 'string') {
        return `
            <span>${data}</span>`
    }
    return `
        <span class="sr-only">yes</span>
        <div class="table-comparison__circle"></div>`
}

function isMatching(data) {
    if (data.differenceCheck) {
        return `
            class="table-comparison__row"`
    } else {
        return `
            class="table-comparison__row table-comparison__row--alt-bg" data-behaviour="check-difference"`
    }
}

// prettier-ignore
const productComparisonMatrix = (data) => (
    `<tr class="show-at-medium">
        <th class="table-comparison__feature" data-element="comparison-product-items colspan="3">${data.description}</th>
    </tr>
    <tr ${isMatching(data)} >
        <td class="table-comparison__feature table-comparison__feature--fixed-width remove-at-medium" data-element="comparison-product-items">${data.description}</td>
        <th class="table-comparison__cell table-comparison__cell--fixed-width u-align-center">
            <span data-element="comparison-product-items">${isFeaturedA(data.productA)}</span>
        </th>
        <th class="table-comparison__cell table-comparison__cell--fixed-width u-align-center">
            <span data-element="comparison-product-items">${isFeaturedB(data.productB)}</span>
        </th>
    </tr>
    `)
export default productComparisonMatrix
