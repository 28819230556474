class FormReset {
    constructor(el) {
        this.trigger = el
        this.form = this.trigger.closest('form')
        this.modifiers = {
            hidden: 'hidden',
        }

        this.hideTrigger()

        el.addEventListener('click', this.handleReset)
        this.form.addEventListener('change', this.showTrigger)
    }

    handleReset = (e) => {
        const event = new Event('form-reset')

        e.preventDefault()
        this.form.reset()
        this.hideTrigger()
        this.form.dispatchEvent(event)
    }

    hideTrigger = () => {
        this.trigger.classList.add(this.modifiers.hidden)
    }

    showTrigger = () => {
        this.trigger.classList.remove(this.modifiers.hidden)
    }
}

export default FormReset
