// prettier-ignore
const standardQuote = (data) => (
`<blockquote class="blockquote quote--centered">
    <p class="blockquote__copy heading--1 heading--lightweight">${data.quote}</p>

    <p class="copy copy--grey u-mar-top-15 u-mar-bot-5">${data.attribution}</p>
    ${data.attribution_title ? `
        <p class="copy copy--grey copy--small">${data.attribution_title}</p>
    ` : ''}
</blockquote>`)

export default standardQuote
