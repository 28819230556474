import { _ } from '../utils.js'
import $ from 'jquery'

export default class DuplicateMarkup {
    constructor(el) {
        this.element = $(el)
        this.trigger = this.element.find(_('duplicate-trigger').str())
        this.container = this.element.find(
            _('duplicate-container', 'element').str(),
        )
        this.prototype = this.container.data('prototype')
        this.limitReachedMessage = _('order-limit-message').el()
        this.index = 1
        this.trigger.on('click', this.addFormElements)
    }

    addFormElements = () => {
        const newForm = this.prototype.replace(/__id__/g, this.index)
        this.container.append(newForm)
        this.index++
        if (this.index < 3) {
            this.limitReachedMessage.hide()
        } else {
            this.limitReachedMessage.show()
        }
    }
}
