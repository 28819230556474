import $ from 'jquery'

class Selector {
    constructor(selector, attribute = 'behaviour') {
        this.main = (thunk) => thunk(attribute, selector)
    }
    str = () =>
        this.main((attribute, selector) => `[data-${attribute}="${selector}"]`)
    el = () => $(this.str())
    node = () => document.querySelector(this.str())
    nodeFrom = (el) => el.querySelector(this.str())
    nodes = () => [...document.querySelectorAll(this.str())]
    nodesFrom = (el) => [...el.querySelectorAll(this.str())]
}

export const _ = (selector, attribute = 'behaviour') => {
    return new Selector(selector, attribute)
}
