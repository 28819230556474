/* global dataLayer */
import $ from 'jquery'
import { _ } from '../utils.js'
import ProductVehicleForm from './product-vehicle-form.js'

const basketForm = _('basket-form').el()
const modalSubmit = _('modal-submit', 'element').el()
const addressBlocks = _('default-address', 'element').el()
const postcodeFind = _('get-installation-details').str()
const datePicker = _('generic-datepicker').str()
const overlay = _('overlay', 'element').el()
const proceedPayment = _('proceed-payment', 'element').el()
let vehicleForm

function copyModalsInfo() {
    _('modal-basket')
        .el()
        .each(function () {
            const thisModal = $(this)
            const thisPostcodeFind = thisModal.find(postcodeFind)
            const thisDatePicker = thisModal.find(datePicker)
            const target = thisModal
                .find(_('modal-submit', 'element').str())
                .data('target')
            const copyBlock = thisModal.find(_('copy-block', 'element').str())
            const copyTarget = _(target, 'element').el()

            if (thisPostcodeFind.val() !== '' || thisDatePicker.val() !== '') {
                $(
                    '[data-element="modal-trigger-block"][data-target="' +
                        target +
                        '"][data-show="1"]',
                ).removeClass('hidden')
                $(
                    '[data-element="modal-trigger-block"][data-target="' +
                        target +
                        '"][data-show="0"]',
                ).addClass('hidden')
                $(
                    '[data-element="modal-errors"][data-target="' +
                        target +
                        '"]',
                ).addClass('hidden')
                copyTarget.html('')
                copyBlock.each(function () {
                    const thisInputVal = $(this)
                        .find('[data-copy-value]')
                        .not('input[type="hidden"]')
                        .val()
                    if (thisInputVal !== '') {
                        copyTarget.append(
                            '<p class="copy--small u-color-almost-dark copy--bold">' +
                                thisInputVal
                                    .replace('AM', '09:00 - 13:00')
                                    .replace('PM', '13:00 - 17:00') +
                                '</p>',
                        )
                    }
                })
            }
        })
}

function checkDefaultAddress(defaultAddressToggle, defaultAddress) {
    if (defaultAddressToggle.is(':checked') === true) {
        const addressValues = defaultAddress.find('[data-default-value]')
        const defaultAddresses = []
        addressValues.map((index, item) => {
            defaultAddresses.push($(item).val())
        })
        addressBlocks.not(defaultAddress).map((index, item) => {
            const copyValues = $(item).find('[data-default-value]')
            const copyTelValue = $(item).find('[data-default-telephone]')
            const mobileVal = defaultAddress.find('[data-default-mobile]').val()
            copyValues.map((copyIndex, copyItem) => {
                $(copyItem).val(defaultAddresses[copyIndex])
            })

            if (mobileVal !== '') {
                copyTelValue.val(mobileVal)
            }
        })
        addressBlocks.not(defaultAddress).find(postcodeFind).trigger('change')

        copyModalsInfo()
    }
}

function checkModalsValidated() {
    modalSubmit.each(function () {
        const thisTarget = $(this).data('parsley-target')
        const validationFocusTarget = $(this).data('validate-focus')
        const id = $(this).data('id')

        if (!basketForm.parsley().isValid(thisTarget)) {
            $('[data-element="date-time-container-' + id + '"]').removeClass(
                'hidden',
            )

            if (
                $(
                    '[data-element="' + thisTarget + '"][data-type="address"]',
                ).contents().length === 0
            ) {
                $(
                    '[data-element="modal-errors"][data-target="' +
                        thisTarget +
                        '"][data-type="address"]',
                )
                $(
                    '[data-element="modal-errors"][data-target="' +
                        thisTarget +
                        '"][data-type="address"]',
                ).removeClass('hidden')
            }

            if (
                $(
                    '[data-element="' + thisTarget + '"][data-type="date"]',
                ).contents().length === 0
            ) {
                $(
                    '[data-element="modal-errors"][data-target="' +
                        thisTarget +
                        '"][data-type="date"]',
                ).removeClass('hidden')
            }

            if (
                $(
                    '[data-element="' + thisTarget + '"][data-type="vehicle"]',
                ).contents().length === 0
            ) {
                $(
                    '[data-element="modal-errors"][data-target="' +
                        thisTarget +
                        '"][data-type="vehicle"]',
                ).removeClass('hidden')
            }

            $(
                '[data-element="address-modal"][data-target="' +
                    validationFocusTarget +
                    '"]',
            ).focus()
        } else {
            $('[data-element="date-time-container-' + id + '"]').addClass(
                'hidden',
            )
            $(
                '[data-element="modal-errors"][data-target="' +
                    thisTarget +
                    '"]',
            ).addClass('hidden')
        }
    })
}

function makeGtmCall(object) {
    window.dataLayer.push(object)
}

function makeGtmLayer(element) {
    const { gtmDataLayer, gtmEventName } = element.dataset
    const object = {
        event: gtmEventName || '',
        ...JSON.parse(gtmDataLayer),
    }
    makeGtmCall(object)
}

function sendForm(e) {
    const orderItemId = $(e.currentTarget).data('order-item-id')

    if (typeof orderItemId !== 'undefined') {
        const thisModal = $(e.currentTarget).closest(
            _('update-vehicle-details', 'element').str(),
        )
        const populateTypeInput = thisModal.find(
            _('populate-type', 'element').str(),
        )
        const populateType = populateTypeInput.val() === 'lookup' ? true : false
        const productData = {
            details: {},
        }
        const vehiclePopulate = thisModal.find(_('vehicle-populate').el())
        const manualInput = thisModal.find(_('manual-input', 'element').el())
        const populateInput = thisModal.find(
            _('populate-input', 'element').el(),
        )
        const regInput = thisModal.find(_('reg-input', 'element').el())
        productData.details[orderItemId] = {
            type: populateType
                ? vehiclePopulate.filter('[data-type="vehicleClass"]').val()
                : manualInput.filter('[data-type="class"]').val(),
            make: populateType
                ? vehiclePopulate.filter('[data-type="make"]').val()
                : manualInput.filter('[data-type="make"]').val(),
            range: populateType
                ? vehiclePopulate.filter('[data-type="range"]').val()
                : manualInput.filter('[data-type="range"]').val(),
            model: populateType
                ? vehiclePopulate.filter('[data-type="model"]').val()
                : manualInput.filter('[data-type="model"]').val(),
            colour: populateType
                ? vehiclePopulate.filter('[data-type="colour"]').val()
                : manualInput.filter('[data-type="colour"]').val(),
            derivative: populateInput.filter('[data-type="derivative"]').val(),
            populateType: populateTypeInput.val(),
            registrationNumber: regInput.val(),
            vin: populateInput.filter('[data-type="vin"]').val(),
        }

        $.ajax({
            url: '/api/basket/save-vehicle-details',
            type: 'patch',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(productData),
            success: () => {
                thisModal
                    .find(_('modal-error', 'element').el())
                    .text('')
                    .addClass('hidden')
                thisModal
                    .find(_('update-success-message', 'element').el())
                    .removeClass('hidden')

                if (
                    basketForm.dataset.gtmEventName &&
                    basketForm.dataset.gtmDataLayer
                ) {
                    makeGtmLayer(basketForm)
                }

                setTimeout(() => {
                    thisModal
                        .find(_('update-success-message', 'element').el())
                        .addClass('hidden')
                }, 3000)
            },
            error: (response) => {
                thisModal
                    .find(_('modal-error', 'element').el())
                    .text(response.responseJSON.message)
                    .removeClass('hidden')
                thisModal
                    .find(_('update-success-message', 'element').el())
                    .addClass('hidden')
            },
        })
    } else {
        const thisInstallationModal = $(e.currentTarget).closest(
            _('target-product-field', 'element').str(),
        )
        $.ajax({
            url: '/api/basket/save-contact-details',
            type: 'post',
            data: basketForm.serialize(),
            success: () => {
                thisInstallationModal
                    .find(_('modal-error', 'element').el())
                    .text('')
                    .addClass('hidden')
                thisInstallationModal
                    .find(_('update-success-message', 'element').el())
                    .removeClass('hidden')

                if (
                    basketForm.dataset.gtmEventName &&
                    basketForm.dataset.gtmDataLayer
                ) {
                    makeGtmLayer(basketForm)
                }

                setTimeout(() => {
                    thisInstallationModal
                        .find(_('update-success-message', 'element').el())
                        .addClass('hidden')
                }, 3000)
            },
            error: (data) => {
                thisInstallationModal
                    .find(_('modal-error', 'element').el())
                    .text(data.responseJSON.error)
                    .removeClass('hidden')
                thisInstallationModal
                    .find(_('update-success-message', 'element').el())
                    .addClass('hidden')
            },
        })
    }
}

function validateModal(e) {
    e.preventDefault()
    const trigger = $(e.currentTarget)
    const thisFieldset = trigger.closest(_('fieldset', 'element').el())
    const parsleyTarget = trigger.data('parsley-target')
    const defaultAddressToggle = thisFieldset.find(
        _('default-address-toggle', 'element').str(),
    )
    const installationBlocks = _('installation-details-block', 'element').el()
    const defaultAddress = thisFieldset.find(
        _('default-address', 'element').str(),
    )
    const validateEitherError = thisFieldset.find(
        _('validate-modal-error-message', 'element').str(),
    )
    vehicleForm = new ProductVehicleForm(basketForm, true)
    basketForm.parsley().on('form:validate', function (formInstance) {
        const ok =
            formInstance.isValid({
                group: parsleyTarget + '-input1',
                force: true,
            }) ||
            formInstance.isValid({
                group: parsleyTarget + '-input2',
                force: true,
            })
        validateEitherError.toggleClass('hidden', ok)
        if (!ok) {
            formInstance.validationResult = false
        }
    })

    basketForm
        .parsley()
        .whenValidate({
            group: parsleyTarget,
        })
        .done(function () {
            checkModalsValidated()
            installationBlocks.removeClass('hidden')
            checkDefaultAddress(defaultAddressToggle, defaultAddress)
            copyModalsInfo()
            sendForm(e)
            if (trigger.data('modal') === true) {
                vehicleForm.updateVehicleDetails(e)
            }
        })
}

function init() {
    modalSubmit.on('click', validateModal)
    copyModalsInfo()
    if (basketForm.length) {
        basketForm.parsley().on('form:error', function () {
            checkModalsValidated()

            // Show address inputs section if validation fails
            basketForm
                .find(_('input-group', 'element').str())
                .removeClass('sr-only')

            const firstPageError = basketForm
                .find('[data-page-error]')
                .not('.hidden')
                .first()
                .parent()

            if (firstPageError.length) {
                $('html, body').animate(
                    {
                        scrollTop:
                            Math.round(firstPageError.offset().top) + 'px',
                    },
                    500,
                )
            }
        })
        basketForm.on('submit', function () {
            try {
                checkModalsValidated()
                proceedPayment.prop('disabled', true)
                overlay.addClass('overlay--active')
                dataLayer.push({ event: 'proceed_to_payment' })
            } catch (error) {
                console.error(error)
            }
        })
    }
}

export default init()
