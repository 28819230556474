export default () => {
    const duplicateFeefoCarousel = document.querySelector(
        'feefowidget-container-carousel+feefowidget-container-carousel',
    )
    const duplicateFeefoContainer = document.querySelector(
        'feefowidget-container-onpage-product + feefowidget-container-onpage-product',
    )
    if (duplicateFeefoCarousel) {
        duplicateFeefoCarousel.remove()
    }

    if (duplicateFeefoContainer) {
        duplicateFeefoContainer.remove()
    }
}
