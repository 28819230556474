import caseStudy from '../classes/case-study-article.js'
import quote from '../classes/quote.js'

export default (data) => {
    if (data.type === 'quote') {
        return quote(data)
    } else if (data.type === 'case-study') {
        return caseStudy(data)
    }
}
