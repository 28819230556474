import $ from 'jquery'
import { _ } from '../utils.js'
import RemoveProduct from './remove-product.js'

export default class ProductSubscriptionPrice {
    constructor(el) {
        this.element = $(el)
        this.productPriceTotal = _('product-price-total', 'element').el()
        this.radioToggle = _('subscription-price-toggle', 'element').str()

        this.element.on(
            'change',
            this.radioToggle,
            this.toggleSubscriptionPrice,
        )
        this.remove = new RemoveProduct()
        this.remove.on('removeProduct', this.toggleSubscriptionPrice)
    }

    toggleSubscriptionPrice = () => {
        const radioGroup = _('product-subscription').el()
        const productLength = radioGroup.length
        const productOriginalPrice = parseFloat(
            this.productPriceTotal
                .data('original-price')
                .replace('£', '')
                .replace('+', ''),
        )
        let subscriptionTotal = 0
        radioGroup.find('input:checked').map((index, item) => {
            const subscriptionPrice = parseFloat(
                $(item)
                    .data('subscription-price')
                    .replace('£', '')
                    .replace('+', ''),
            )
            subscriptionTotal = subscriptionTotal + subscriptionPrice
        })

        const totalPrice =
            productLength * productOriginalPrice + subscriptionTotal

        this.productPriceTotal.text(
            '£' +
                totalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
        )

        // console.log(subscriptionTotal)
        // console.log(totalPrice)
        // _('chosen-subscription-price', 'element').el().val(subscriptionTotal)
    }
}
