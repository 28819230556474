import { _ } from '../utils.js'

export default (el) => {
    const element = el
    const targets = _('equal-height-target', 'element').nodesFrom(el)
    const breakpoint = element.dataset.breakpoint
    let height = 0

    const setHeight = (elementHeight) => {
        height = elementHeight > height ? elementHeight : height
    }

    const setHeights = () => {
        targets.forEach((equalHeightElement) => {
            equalHeightElement.style.height = `${height}px`
        })
    }

    if (window.innerWidth > Number(breakpoint)) {
        targets.forEach((equalHeightElement) => {
            const elementHeight = equalHeightElement.clientHeight
            setHeight(elementHeight)
        })
        setHeights()
    }
}
