import $ from 'jquery'
import { _ } from '../utils.js'

export default class BasketContact {
    constructor(el) {
        this.element = $(el)
        this.postCodeInput = this.element.find($(_('address-finder-main').el()))
        this.inputGroup = this.element.find(
            $(_('input-group', 'element').str()),
        )

        this.postCodeInput.on('change', this.showForm)

        if (this.inputGroup.data('show') === 1) {
            this.showForm()
        }
    }

    showForm = () => {
        this.inputGroup.removeClass('sr-only')
    }
}
