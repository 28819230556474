import $ from 'jquery'
import { _ } from '../utils.js'

export default class FilterProductType {
    constructor(el) {
        this.element = $(el)
        this.selectType = this.element.find(_('select-product-type').el())
        this.product = this.element.find(_('product', 'element').str())

        this.selectType.on('change', this.filterProducts)
        this.filterProducts()
    }

    filterProducts = () => {
        const selectedType = this.selectType.val()
        if (selectedType === 'individual') {
            this.product.each(function () {
                if (!$(this).data('individual')) {
                    $(this).hide()
                } else {
                    $(this).show()
                }
            })
        } else if (selectedType === 'business') {
            this.product.each(function () {
                if (
                    !$(this).data('business-only') &&
                    !$(this).data('business')
                ) {
                    $(this).hide()
                } else {
                    $(this).show()
                }
            })
        } else if (selectedType === 'all') {
            this.product.show()
        }
    }
}
