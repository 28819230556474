const template = (data) =>
    `<div class="grid__item">
    <a href="${data.url}" class="card">
        <div class="card__image">
            <img class="card__media" src="${data.imageUrl}" />
            <span class="tag">${data.categories}</span>
        </div>
        <h1 class="card__heading heading--4">
            ${data.name}<br>
            <time class="card__date">${data.date}</time>
        </h1>

        <span class="button button--small">Read Now</span>
    </a>
</div>`

export default template
