import $ from 'jquery'

export default class BasketCountData {
    constructor(el) {
        this.element = $(el)

        if (this.element.data('count') === 0) {
            document.cookie = 'basketItem=false;path=/'
        }
    }
}
