/* eslint-disable camelcase */

export default (el) => {
    const element = el

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            currency: 'GBP',
            value: element.dataset.gtmTotalValue || null,
            items: [
                {
                    item_id: element.dataset.gtmProductId || null,
                    item_name: element.dataset.gtmProductName || null,
                    affiliation: 'tracker',
                    coupon: null,
                    discount: null,
                    index: 0,
                    item_brand: 'tracker',
                    item_list_id: 'related_products',
                    item_list_name: 'Related Products',
                    location_id: null,
                    price: element.dataset.gtmTotalValue || null,
                    quantity: 1,
                },
            ],
        },
    })
}
