/* eslint-disable camelcase */
import $ from 'jquery'
import { _ } from '../utils.js'
import ProductComparison from '../modules/product-comparison.js'
import trackerToolResult from '../classes/tracker-tool-result.js'

export default class MultistepForm {
    constructor(el) {
        this.element = $(el)
        this.currentStepValue = 0

        this.form = this.element.find(_('form', 'element').str())
        this.formInput = this.element.find(_('input').str())
        this.featureInput = this.element.find(_('feature', 'element').str())
        this.classInput = this.element.find(_('class', 'element').str())
        this.formResults = this.element.find(
            _('multistep-form-results', 'element').str(),
        )
        this.resultingProducts = this.element.find(
            _('resulting-products', 'element').str(),
        )
        this.resultsTotal = this.element.find(
            _('tracker-tool-total', 'element').str(),
        )
        this.resultsTotalContainer = this.element.find(
            _('tracker-tool-total-container', 'element').str(),
        )
        this.errorMessage = this.element.find(
            _('error-message', 'element').str(),
        )
        this.clearFormButton = this.element.find(_('clear-form').el())

        this.clearFormButton.on('click', this.clearForm)
        this.formInput.on('change', this.submitForm)
        this.form.on('form-reset', this.submitForm)

        this.preSelectedClass()
    }

    startAgain = (e) => {
        this.searchAgain.addClass('hidden')

        this.clearForm(e)
    }

    clearForm = () => {
        this.resultingProducts.empty()
        this.resultsTotal.empty()
        this.resultsTotalContainer.addClass('hidden')
        this.errorMessage.addClass('hidden')
        this.featureInput.find(_('input').str()).prop('disabled', false)
        this.featureInput.removeClass('checkbox--disabled')
        this.formInput.prop('checked', false)

        this.scrollTopForm()
        this.submitForm()
    }

    preSelectedClass = () => {
        const self = this
        if ($(this).prop('checked', true)) {
            self.submitForm()
        }
    }

    submitForm = (e) => {
        const sendData = this.form.serialize()

        if (e) {
            e.preventDefault()
        }

        $.ajax({
            url: '/api/tracker-tool',
            type: 'GET',
            data: sendData,
            success: (data) => {
                this.resultingProducts.empty()
                this.resultsTotal.empty()
                this.skipStep = data.form && data.form.skipStep3
                this.formResults.removeClass('sr-only')
                // prettier-ignore
                window.dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        item_list_id: 'related_products',
                        item_list_name: 'Related products',
                        items:
                            data.results.products.length > 0
                                ? data.results.products.map((item, index) => ({
                                    item_id: item.id,
                                    item_name: item.heading,
                                    affiliation: 'tracker',
                                    coupon: null,
                                    discount: null,
                                    index,
                                    item_brand: 'tracker',
                                    item_category: item.productCategory,
                                    item_list_id: 'related_products',
                                    item_list_name: 'Related Products',
                                    price: item.price,
                                    quantity: 1,
                                }))
                                : [],
                    },
                })

                if (data.results.total > 0) {
                    data.results.products.map((item) => {
                        this.resultingProducts.append(trackerToolResult(item))
                    })

                    data.form.features.map((item, index) => {
                        $(this.featureInput[index]).toggleClass(
                            'checkbox--disabled',
                            item.disabled,
                        )
                        $(this.featureInput[index])
                            .find(_('input').str())
                            .prop('disabled', item.disabled)
                    })

                    this.resultsTotal.append(data.results.total)
                    this.resultsTotalContainer.removeClass('hidden')
                    this.errorMessage.addClass('hidden')
                    _('select-item', 'element')
                        .el()
                        .map((i, el) => {
                            el.addEventListener('click', () => {
                                window.dataLayer.push({
                                    event: 'select_item',
                                    ecommerce: {
                                        item_list_id: 'related_products',
                                        item_list_name: 'Related products',
                                        items: [
                                            {
                                                item_id: el.dataset.gtmItemId,
                                                item_name:
                                                    el.dataset.gtmItemName,
                                                affiliation: 'tracker',
                                                coupon: null,
                                                discount: null,
                                                index: i,
                                                item_brand: 'tracker',
                                                item_category:
                                                    el.dataset.gtmItemCategory,
                                                item_list_id:
                                                    'related_products',
                                                item_list_name:
                                                    'Related Products',
                                                price: el.dataset.gtmItemPrice,
                                                quantity: 1,
                                            },
                                        ],
                                    },
                                })
                            })
                        })
                } else {
                    this.resultsTotalContainer.addClass('hidden')
                    this.errorMessage.removeClass('hidden')
                }

                _('product-comparison')
                    .el()
                    .map((i, el) => new ProductComparison(el))
            },
            error: function (err) {
                console.error(err)
            },
        })
    }

    scrollTopForm = () => {
        const offsetTop = this.element.offset().top
        window.scrollTo(0, offsetTop - 134)
    }
}
