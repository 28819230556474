/* eslint-disable camelcase */
/* global dataLayer */
import $ from 'jquery'
import { _ } from '../utils.js'

class BasketRemoveProduct {
    constructor() {
        const removeTrigger = _('basket-remove-product').str()
        this.productFields = _('target-product-field', 'element').el()
        this.productParent = _('target-product', 'element').el()
        this.priceRow = _('target-price-row', 'element').str()
        this.priceDiscount = _('price-discount', 'element').el()
        this.priceTotal = _('price-total', 'element').el()
        this.promoCodeInput = _('promo-code-input', 'element').el()
        this.promoCodeModal = _('promo-code-modal', 'element').el()
        this.displayDiscount = _('display-discount', 'element').el()

        $(document).on('click', removeTrigger, this.removeAndUpdatePrices)
    }

    removeAndUpdatePrices = (e) => {
        e.preventDefault()

        const trigger = $(e.currentTarget)
        const orderId = $(e.currentTarget).data('order-id')
        const productId = $(e.currentTarget).data('product-id')
        const productName = $(e.currentTarget).data('product-name')
        const productCategory = $(e.currentTarget).data('product-category')
        const productPrice = $(e.currentTarget)
            .data('product-price')
            .replace('+', '')
            .replace('£', '')
        const target = trigger.closest(this.productFields)

        const subscriptionSelectElement = target.find(
            '[data-element="subscription-price-toggle"]',
        )
        const selectedOption = subscriptionSelectElement.find('option:selected')
        const subscriptionPriceText = selectedOption
            .text()
            .replace('+', '')
            .replace('£', '')
        const subscriptionPrice = parseInt(subscriptionPriceText, 10)

        const targetParent = trigger.closest(this.productParent)
        const redirectUrl = trigger.data('url')

        $.ajax({
            url: '/api/basket/remove/' + orderId,
            method: 'get',
            success: (response) => {
                target.remove()
                const fieldNo = targetParent.find(this.productFields).length
                if (fieldNo < 1) {
                    targetParent.remove()
                    window.location.href = redirectUrl
                }
                _('basket-items').el().text(response.count)

                $(this.priceRow + '[data-order-id="' + orderId + '"]').remove()
                this.priceDiscount.text(
                    '-£' +
                        response.discount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                )
                this.priceTotal.text(
                    '£' +
                        response.total.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                )
                this.promoCodeInput.val('')
                this.promoCodeModal.addClass('hidden')

                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
                dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        currency: 'GBP',
                        value: parseInt(productPrice, 10) + subscriptionPrice,
                        items: [
                            {
                                item_id: productId,
                                item_name: productName,
                                affiliation: 'tracker',
                                coupon: null,
                                discount: null,
                                index: 0,
                                item_brand: 'tracker',
                                item_category: productCategory,
                                item_list_id: 'related_products',
                                item_list_name: 'Related Products',
                                item_variant: null,
                                location_id: null,
                                price:
                                    parseInt(productPrice, 10) +
                                    subscriptionPrice,
                                quantity: 1,
                            },
                        ],
                    },
                })

                dataLayer.push({
                    event: 'removeFromCart',
                    ecommerce: {
                        currencyCode: 'GDP',
                        remove: {
                            products: [
                                {
                                    name: productName,
                                    id: productId,
                                    price:
                                        parseFloat(productPrice) +
                                        subscriptionPrice, // installed price + subscription price
                                    brand: 'Tracker',
                                    category: productCategory,
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                })
            },
            error: (response) => {
                console.error(
                    'error - ' + response.code + ': ' + response.message,
                )
            },
        })
    }
}

export default new BasketRemoveProduct()
